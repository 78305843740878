import { render, staticRenderFns } from "./Replace.vue?vue&type=template&id=2b3e5ac6&functional=true"
import script from "./Replace.vue?vue&type=script&lang=js"
export * from "./Replace.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../client/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports