import { render, staticRenderFns } from "./Expand-less.vue?vue&type=template&id=78f6b4a7&functional=true"
import script from "./Expand-less.vue?vue&type=script&lang=js"
export * from "./Expand-less.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../client/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports