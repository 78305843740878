import axios from "axios";
import isObject from "lodash/isObject";
import { cacheAdapterEnhancer } from "axios-extensions";
import i18n from "@/i18n";
import router from "@/router";
import store from "@/store";
import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";
import { checkForBuildVersionChange } from "../versionManager/index";
import handleFeedbackMessages from "./handleFeedbackMessages";

/* eslint-disable prefer-promise-reject-errors */
const http = axios.create({
  baseURL: `/api`,
  withCredentials: true,
  timeout: 20000,
  adapter: cacheAdapterEnhancer(axios.getAdapter(axios.defaults.adapter), { enabledByDefault: false, cacheFlag: "useCache" }),
  headers: {
    "Accept-Language": i18n.locale
  }
});

/* Snipets taken from https://github.com/axios/axios/issues/5630#issuecomment-1828448874 */
http.defaults.paramsSerializer = params =>
  Object.keys(params)
    .filter(key => params[key] !== undefined)
    .map(key => {
      if (isObject(params[key])) {
        params[key] = JSON.stringify(params[key]);
      }
      if (Array.isArray(params[key])) {
        return params[key].map(v => `${key}[]=${encodeURIComponent(v)}`).join("&");
      }
      return `${key}=${encodeURIComponent(params[key])}`;
    })
    .join("&");

http.interceptors.response.use(
  response => {
    /**
     * Compare local build version with remote build version
     * and update application if needed.
     */
    handleFeedbackMessages(response);
    checkForBuildVersionChange(response);
    return response;
  },
  /* If status is outside the range of 2xx, trigger this function
    error.response.data contains the errorMessage from server */
  error => {
    handleFeedbackMessages(error);
    if (error.response?.status === 404) {
      router
        .push({ name: "notFound", params: { errorMessage: error.response?.data?.errorMessage || defaultErrorMessages.notFound } })
        .catch(() => {});
      return Promise.reject(error.response);
    }

    if (error.response?.status === 403) {
      store.dispatch("login/logoutUser");
      return Promise.reject(error.response);
    }

    if (error.response?.status === 401) {
      router
        .push({ name: "Unauthorized", params: { errorMessage: error.response?.data?.errorMessage || defaultErrorMessages.unauthorized } })
        .catch(() => {});
      return Promise.reject(error.response);
    }

    if (error.response?.status === 502) {
      router
        .push({ name: "badGateway", params: { errorMessage: error.response?.data?.errorMessage || defaultErrorMessages.server } })
        .catch(() => {});
      return Promise.reject(error.response);
    }

    if (error.response?.data?.errorMessage) {
      return Promise.reject(error.response);
    }

    const response = { data: { error, errorMessage: defaultErrorMessages.default } };
    return Promise.reject(response);
  }
);

export default http;
